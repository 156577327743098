import styled from 'styled-components';

import { ReactComponent as SuccessIcon } from './assets/success.svg';
import { Text } from '../Typography/Text';
import { StatusType } from '../../types/transaction';

export const StyledLabel = styled.div<{$status?: StatusType}>`
  display: flex;
  align-items: center;
  max-width: fit-content;
`;

export const StyledText = styled(Text)`
  text-transform: capitalize;
  margin-left: 6px;
`;

export const StyledSuccessIcon = styled(SuccessIcon)`
`;

export const StyledCenterLoader = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
