import { useEffect, useState } from 'react';
import { queryRoutes as routes } from '../constants/routes';
import { useQuery } from './query';
import { Transaction, TransactionType } from '../types/transaction';
import { TokensType } from '../types/tokens';

export const useGetAccount = (address: string) => {
  const [transactions, setTransactions] = useState<Array<TransactionType>>();
  const [tokens, setTokens] = useState<TokensType[]>();
  const [isFetch, setFetch] = useState(true);
  const {
    data: response, error, url, query,
  } = useQuery({ path: routes.account, params: { id: address, limit: 15 } });

  const { data: tokensData } = useQuery({ path: routes.account, params: { id: `${address.toLowerCase()}/tokens/owner` } });

  const fetchMore = async () => {
    if (response?.transactions?.paging?.nextCursor && isFetch) {
      setFetch(false);
      url.searchParams.set('cursor', response?.transactions?.paging.nextCursor);
      await query();
      setFetch(true);
    }
  };

  useEffect(() => {
    setTokens(tokensData?.data);
  }, [tokensData]);

  useEffect(() => {
    const actualData = response?.transactions?.data;
    if (!actualData || !actualData.length) return;
    const newData = transactions || [];
    actualData.forEach((tx: TransactionType) => {
      if (!tx.hash) return;
      if (transactions?.find((t) => t.hash === tx.hash)) {
        return;
      }
      newData.push(tx);
    });
    setTransactions(newData);
  }, [response?.transactions?.paging?.nextCursor]);

  return {
    data: {
      ...response, tokens, transactions: transactions || [], countTx: response?.transactions?.countTx,
    },
    error,
    fetchMore,
    hasMore: (response?.transactions?.paging.hasMore && isFetch),
  };
};

export const useGetToken = (address: string) => {
  const [isFetch, setFetch] = useState(true);
  const [token, setToken] = useState<TokensType>();
  const [transactions, setTransactions] = useState<Transaction[]>();

  const {
    data: response, error, url, query,
  } = useQuery({ path: '/api/token', params: { id: `${address.toLowerCase()}/tx`, limit: 15, parameters: ['decodeInput'] } });
  const {
    data: tokenData,
  } = useQuery({ path: '/api/token', params: { id: `${address.toLowerCase()}` } });

  const fetchMore = async () => {
    if (response?.paging?.nextCursor && isFetch) {
      setFetch(false);
      url.searchParams.set('cursor', response?.paging.nextCursor);
      await query();
      setFetch(true);
    }
  };

  useEffect(() => {
    if (!tokenData) return;
    setToken(tokenData);
  }, [tokenData]);

  useEffect(() => {
    const actualData = response?.data;
    if (!actualData || !actualData.length) return;
    const newData = transactions || [];
    actualData.forEach((tx: Transaction) => {
      if (!tx.hash) return;
      if (transactions?.find((t) => t.hash === tx.hash)) {
        return;
      }
      newData.push(tx);
    });
    setTransactions(newData);
  }, [response?.paging?.nextCursor]);

  return {
    data: {
      token,
      transactions: transactions || [],
      countTx: response?.count,
    },
    error,
    fetchMore,
    hasMore: (response?.paging.hasMore && isFetch),
  };
};
