import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import { StyledLabel, StyledSuccessIcon, StyledText } from './styles';
import { StatusType } from '../../types/transaction';

export const Label: React.FC<{status: StatusType}> = ({ status }) => (
  <StyledLabel $status={status}>
    {status === StatusType.pending && <PulseLoader size={14} color="#E6E7E7" />}
    {status === StatusType.success && <StyledSuccessIcon />}
    <StyledText>{status}</StyledText>
  </StyledLabel>
);
