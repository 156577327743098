import { BlockType } from './block';

export type TransactionType = {
    nonce: number,
    gasPrice: number,
    gas: number,
    from: string,
    value: number,
    data: string,
    hash: string,
    blockHashes: string[],
    to: string,
    timestamp: number,
    blocks: BlockType[],
    status?: StatusType,
}

export enum StatusType {
    success = 'success',
    pending = 'pending',
}

export enum ServerOperationsTypes {
    Create = 'Create',
    Transfer = 'Transfer',
    Mint = 'Mint',
    Burn = 'Burn',
    Unknown = 'Unknown',
}
export type Transaction = {
    blockNumber: number;
    from: string;
    gas: number;
    gasPrice: string;
    maxFeePerGas: string;
    maxPriorityFeePerGas: string;
    hash: string;
    input: string;
    nonce: number;
    to: string;
    transactionIndex: number;
    value: string | null;
    type: number;
    accessList: [];
    chainId: string;
    v: string;
    r: string;
    s: string;
    blockHashes: string[];
    timestamp: number;
    decodeInput: decode;
};

export interface decode {
    standard: number;
    commonAddress: string;
    tokenId: string;
    owner: string;
    spender: string;
    operator: string;
    from: string;
    to: string;
    value: string;
    index: string;
    isApproved: string;
    data: string;
    operation: string;
    decimal: number;
    name: string;
    symbol: string;
    totalSupply: string;
}
export interface TransactionInfo {
    hash: string;
    to: string;
    from: string;
    value: string;
    tokenAddress: string;
    name: string;
    symbol: string;
    tokenId: string;
    standard: number;
    operation: ServerOperationsTypes;
    timestamp: number;
}
